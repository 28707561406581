/* * {border: 1px solid black;} */

body {
  background-color: goldenrod;
  color: ivory;
  font-family: 'Montserrat', sans-serif;
}

a:link {
  text-decoration: none;
  color: ivory;
}

a:hover {
  text-decoration: none;
  color: ivory;
}

a:visited {
  text-decoration: none;
  color: ivory;
}
/*  HEADER  */

h1 {
  font-family: 'Rubik Mono One', sans-serif;
  font-size: 3em;
  margin: 0%;
  transition-property: color;
  transition-duration: 2s;
  transition-delay: 0.25s;
}

h1:hover {
  color: rgb(16, 140, 212);
  cursor: pointer;
}

/*  NAVBAR  */

.navbar {
  margin: 0%;
  padding-bottom: 1%;
  list-style: none;
}

.navbar > li {
  display: inline;
  margin-inline: 1%;
  transition-property: text-shadow;
  transition-duration: 0.5s;
}

.navbar > li:hover {
  display: inline;
  margin-inline: 1%;
  cursor: pointer;
  text-shadow: black 1px 1px 10px;
}


/*  PORTRAIT  */
.portrait {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* display: flex;
  justify-content: center; */
}

.portrait > img {
  max-height: 75vh;
  border: dotted white;
}

/*  CONTENT  */
.content-body {
  /* border: solid 1px black; */
  margin-inline: 5%;
  width: 90vw;
  height: 80vh;
  overflow-y: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
}

.content-body > p {
  margin: 0%;
  padding: 0%;
}

h3 {
  padding: 2%;
}

/*  WORKS  */

.app-container {
  width: 95%%;
  height: 85%;
  border: dotted white;
  padding: 1%
}

.proj-img {
  max-height: 50%;
  max-width: 80%;
}

.repo-link {
  padding-right: 2%;
}

h4 {
  font-family: 'Rubik Mono One', sans-serif;
  font-size: 1.6em;
  margin: 0%;
}


/*  FOOTER  */
.footer {
  text-align: center;
  font-family: 'Rubik Mono One', sans-serif;
  font-size: 0.6em;
}

.name-span {
  font-size: 1.2em;
  transition-property: color;
  transition-duration: 2s;
  transition-delay: 0.25s;
}

.name-span:hover {
  color: rgb(16, 140, 212);
  cursor: pointer;
}